<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h3 class="mb-0">Technical Analysis</h3>
            <div class="d-flex align-items-center">
                <ul class="toplinks boxed d-flex align-items-center mb-0 me-3 hideMobile">
                    <li @click="
                      type = '';
                      getdata();
                    ">
                        <a href="javascript:void(0);" :class="[{ active: type == '' }]">All</a>
                    </li>
                    <li @click="
                      type = 'Forex';
                      getdata();
                    ">
                        <a href="javascript:void(0);" :class="[{ active: type == 'Forex' }]">Forex</a>
                    </li>
                    <li @click="
                      type = 'Crypto';
                      getdata();
                    ">
                        <a href="javascript:void(0);" :class="[{ active: type == 'Crypto' }]">Crypto</a>
                    </li>
                    <li @click="
                      type = 'Commodities';
                      getdata();
                    ">
                        <a href="javascript:void(0);" :class="[{ active: type == 'Commodities' }]">Commodities</a>
                    </li>
                    <li @click="
                      type = 'Indices';
                      getdata();
                    ">
                        <a href="javascript:void(0);" :class="[{ active: type == 'Indices' }]">Indices</a>
                    </li>
                </ul>
                <router-link to="/all-market-insights" class="linkBtn d-flex align-items-center">See All
                    <vue-feather size="17px" type="chevron-right" class="chevron-right ms-1"></vue-feather>
                </router-link>
            </div>
        </div>
        <div class="row mob-scroll-content"
            v-if="store.financialContent.totalRecords && store.financialContent.totalRecords > 0">
            <div class="col-12 col-lg-6 mb-4" v-for="(item, key) in store.financialContent.records.slice(0, 4)"
                :key="key">
                <div class="media d-flex">
                    <div class="flex-shrink-0 mktInsite">
                        <img :src="item.image_url" alt="Image" />
                    </div>
                    <div class="flex-grow-1 ms-2">
                        <h6 class="medium line-clamp" @click="() => goToDetailPage(item)">
                            <a class="d-block" href="javascript:void(0);">{{ (item.source == 'FXS') ? item.title :
                            item.metadata.header}}</a>
                        </h6>
                        <p class="clock f-12 mb-2">
                            <vue-feather type="clock" class="clock-icon"></vue-feather>
                            {{formatNow(item.created,'now',0)}}
                        </p>
                        <p class="line-clamp" v-html="item.text.slice(0, 70) + '...'"></p>
                    </div>
                </div>
            </div>
        </div>
        <no-record v-else></no-record>
        <!-- Open Modal -->
        <!-- <div class="modal fade ideaModal" v-if="Object.keys(showNews).length > 0"
            :class="Object.keys(showNews).length > 0 ? 'show' : ''">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="row modal-content g-0 h-100">
                    <div class="col-12 col-lg-6 ideaChart">
                        <img class="d-block w-100 h-100" :src="showNews.image_url" alt="News" title="News Image" />
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                        <div class="modal-body p-4 pt-0 border-0">
                            <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex me-3">
                                            <vue-feather size="23px" type="share-2"></vue-feather>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex">
                                            <vue-feather size="23px" type="printer"></vue-feather>
                                        </a>
                                    </li>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="showNews = {}"></button>
                            </div>
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4">
                                    <a class="green" href="portfolios.com">{{
                                    showNews.source
                                    }}</a>
                                </li>
                                <li>{{ formatNow(showNews.created, 'now',0) }}</li>
                            </ul>
                            <h2 class="medium my-4 py-2">{{(showNews.source != 'FXS') ?
                            showNews.metadata.header.slice(0, 50) : showNews.title}}</h2>
                            <p v-html="showNews.text"></p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import { useMainStore } from "@/store";
import moment from "moment";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            type: "",
        };
    },
    // watch: {
    //   showNews(val) {
    //     const el = document.body;
    //     if (Object.keys(val).length > 0) {
    //       if (el.classList.contains("position-fixed")) {
    //         el.classList.remove("position-fixed");
    //       } else {
    //         el.classList.add("position-fixed");
    //       }
    //     } else {
    //       el.classList.remove("position-fixed");
    //     }
    //   },
    // },
    computed: {
    },
    methods: {
        goToDetailPage(item){
            let title = ''
            if(item.source != 'FXS'){
                title = item.metadata.header
            }else{
                title = item.title
            }
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    if(item.source != 'FXS'){
                        title = item.metadata.header
                    }else{
                         title = item.title
                    }
                }
            }
            this.$router.push({
                name: 'insight_detail',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
            });
        },
        calinsightsDetail() {
            let form = {
                '_id': this.showNews._id
            }
            this.store
                .callFinancialContentDetail(form, false)
                .then((response) => {
                })
                .catch();
        },
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(`${date.split(' ')[0]} ${date.split(' ')[1]}`, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        // formatNow(val) {
        //   return moment(val).fromNow();
        // },
        getdata() {
            let formData = {
                page: 1,
                perPage: 15,
            };
            if (this.type != "") {
                formData["category"] = this.type;
            }
            this.store
                .callFinancialContent(formData, false)
                .then(() => { })
                .catch((err) => console.log(err.message));
        },
    },
    created() {
        this.getdata();
    },
};
</script>
