<template>
    <div class="d-flex justify-content-between align-items-center mb-4">
        <h3 class="mb-0">What's Trending</h3>
        <div class="d-flex align-items-center mob-wrap">
            <ul class="toplinks boxed pb-2 d-flex align-items-center mb-0 me-3 mob-scroll hideMobile">
                <li @click="
                  type = '';
                  getTradingNews();
                ">
                    <a href="javascript:void(0);" :class="[{ active: type == '' }]">All</a>
                </li>
                <li @click="
                  type = 'FOREX';
                  getTradingNews();
                ">
                    <a href="javascript:void(0);" :class="[{ active: type == 'FOREX' }]">Forex</a>
                </li>
                <li @click="
                  type = 'CRYPTO';
                  getTradingNews();
                ">
                    <a href="javascript:void(0);" :class="[{ active: type == 'CRYPTO' }]">Crypto</a>
                </li>
                <li @click="
                  type = 'COMMODITIES';
                  getTradingNews();
                ">
                    <a href="javascript:void(0);" :class="[{ active: type == 'COMMODITIES' }]">Commodities</a>
                </li>
                <li @click="
                  type = 'INDICES';
                  getTradingNews();
                ">
                    <a href="javascript:void(0);" :class="[{ active: type == 'INDICES' }]">Indices</a>
                </li>
            </ul>
            <router-link to="/all-news" class="linkBtn d-flex align-items-center">See All
                <vue-feather size="17px" type="chevron-right" class="chevron-right ms-1"></vue-feather>
            </router-link>
        </div>
    </div>
    <div class="row mob-scroll-content" v-if="store.trendingNews.totalRecords && store.trendingNews.totalRecords > 0">
        <div class="col-12 col-lg-4 mb-3" v-for="(item, key) in store.trendingNews.records.slice(0, 6)" :key="key">
            <div class="media d-flex">
                <div class="flex-shrink-0 width-80 me-2">
                    <img v-if="item.details" :src="item.details.image" alt="Image" width="80" height="80"/>
                </div>
                <div class="flex-grow-1 ms-2">
                    <p class="medium line-clamp mb-3">
                        <a v-if="item.details" href="javascript:void(0)"
                            @click="()=> goToDetailPage(item.details)">{{ item.details.title }}</a>
                    </p>
                    <p class="line-clamp clock f-12 mb-2" v-if="item.details">
                        <vue-feather type="clock" class="clock-icon"></vue-feather>
                        {{formatNow(item.details.published,'now',0)}}
                    </p>
                </div>
            </div>
        </div>
        <!-- Open Modal -->
        <div class="modal fade ideaModal" v-if="Object.keys(showNews).length > 0"
            :class="Object.keys(showNews).length > 0 ? 'show' : ''">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="row modal-content g-0 h-100">
                    <div class="col-12 col-lg-6 ideaChart">
                        <img class="d-block w-100 h-100" :src="showNews.image" alt="News" title="News Image" />
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                        <div class="modal-body p-4 pt-0 border-0">
                            <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex me-3">
                                            <vue-feather size="23px" type="share-2"></vue-feather>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex">
                                            <vue-feather size="23px" type="printer"></vue-feather>
                                        </a>
                                    </li>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="showNews = {}"></button>
                            </div>
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4">
                                    <a class="green" href="portfolios.com">{{
                                    showNews.source
                                    }}</a>
                                </li>
                                <li>{{ formatNow(showNews.published, 'now',0) }}</li>
                            </ul>
                            <h2 class="medium my-4 py-2">{{ showNews.title }}</h2>
                            <p v-html="showNews.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <no-record v-else></no-record>
</template>
<script>
import { useMainStore } from "@/store";
import moment from "moment";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            type: "",
        };
    },
    methods: {
        goToDetailPage(item){
            let title = item.title
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    title = item.title
                }
            }
            this.$router.push({
                name: 'news_details',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
            });
        },
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(`${date.split(' ')[0]} ${date.split(' ')[1]}`, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        calnewsDetail() {
            let form = {
                '_id': this.showNews._id
            }
            this.store
                .callNewsDetails(form, false)
                .then((response) => {
                })
                .catch();
        },
        // formatNow(val) {
        //   return moment(val).fromNow();
        // },
        getTradingNews() {
            let formData = {
                page: 1,
                perPage: 8,
            };
            if (this.type != "") {
                formData["category_type"] = this.type;
            }
            this.store
                .callTrendingNews(formData, false)
                .then(() => { })
                .catch((err) => console.log(err.message));
        },
    },
    created() {
        this.getTradingNews();
    },
};
</script>
