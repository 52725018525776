<template>
    <div class="map row position-relative mx-0">
        <div class="col-12 mb-4">
            <div class="d-flex justify-content-between align-items-center"><h3 class="mb-0">Economy Overview</h3><a href="/economy-overview" class="cardEelement">See all</a></div>
        </div>
        <div class="col-12">
            <div class="row bodyBG p-3 radius-5">
                <div class="col-12 col-xl-7">
                  <span class ="lastSelectedcountry" v-if="lastCountry">{{lastCountry}}</span>
                    <div class="rootDirection">
                        <div id="homechartdiv" v-show="Object.keys(store.economicOverview).length > 0"></div>
                        <div class="price-wrap d-flex align-items-center justify-content-center" v-if="Object.keys(store.economicOverview).length > 0">
                            <div class="price whiteBG radius-8 d-flex align-items-center" v-for="(item, index) of otherIndicators" :key="item.key">
                                <img :src="getImage(item.key)" class="float-left" />
                                <div class="rgtPrice ps-2 ps-md-3">
                                <h6 class="mb-1">{{showBelowData[item.key] && showBelowData[item.key].HistoricalDataSymbol ? showBelowData[item.key].HistoricalDataSymbol : ""}}</h6>
                                    <p class="line-1 m-0">{{showBelowData[item.key] && showBelowData[item.key].LatestValue ? showBelowData[item.key].LatestValue : "N/A"}}</p>
                                    <p
                                        class="mb-0 f-12"
                                        :class="[{red: ((showBelowData[item.key].LatestValue - showBelowData[item.key].PreviousValue) / showBelowData[item.key].PreviousValue ) * 100 < 0,},{green: ((showBelowData[item.key].LatestValue - showBelowData[item.key].PreviousValue) / showBelowData[item.key].PreviousValue)*100 > 0,},]"
                                    >
                                        {{showBelowData[item.key] && showBelowData[item.key].LatestValue && showBelowData[item.key].PreviousValue ? `${parseFloat( ((showBelowData[item.key].LatestValue - showBelowData[item.key].PreviousValue)
                                        / showBelowData[item.key].PreviousValue )*100).toFixed(2)}%` : ""}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-5 data-wrap mt-xl-4">
                    <div class="row">
                        <div class="col-6 mb-3" v-for="(item, index) of economyOverviewIndicators" :key="item.key">
                            <div class="dataValue whiteBG radius-5 border" v-if="Object.keys(showData).length > 0">
                                <p class="f-12 d-flex align-items-center justify-content-between mb-1 medium gdp">
                                    <!--{{item.name}}-->{{item.name}} {{item.key !='Business Confidence' && showData[item.key] && showData[item.key].Unit !="percent" ? showData[item.key].Unit : ""}}
                                    <span class="green regular">{{showData[item.key] && showData[item.key].LatestValue ? showData[item.key].LatestValue : "N/A"}}{{showData[item.key] && showData[item.key].Unit=="percent" ? "%" : ""}}</span>
                                </p>
                                <!-- <p class="f-11">{{item.key=="GDP" || item.key=="Business Confidence" ? item.key : ""}}{{showData[item.key] && showData[item.key].Unit !="percent" ? showData[item.key].Unit : ""}}</p>-->
                                <div>
                                    <div class="indexOverView" :id="'barChartdiv' + index" style="width: 100%; height: 110px;" v-show="Object.keys(showData[item.key]).length"></div>
                                    <div class="indexOverView" style="width: 100%; height: 110px;" v-show="!Object.keys(showData[item.key]).length">No data</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import $ from "jquery";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      economyOverviewIndicators: [
        { name: "GDP", key: "GDP" },
        { name: "GDP Growth", key: "GDP Annual Growth Rate" },
        { name: "Inflation Rate", key: "Inflation Rate" },
        { name: "Interest Rate", key: "Interest Rate" },
        { name: "Credit Rating", key: "Credit Rating" },
        { name: "Business Confidence", key: "Business Confidence" },
      ],
      lastCountry: "",
      start : [],
      end : [],
      otherIndicators: [
        { name: "Currency", key: "Currency" },
        { name: "Stock Market", key: "Stock Market" },
        { name: "Government Bond 10Y", key: "Government Bond 10Y" },
      ],
      isMobile : false
    };
  },
  computed: {
    showData() {
      if (
        Object.keys(this.store.economicOverview).length > 0 &&
        this.lastCountry
      ) {
        let json = {};
        for (let item of this.economyOverviewIndicators) {
          if (
            this.store.economicOverview[item.key] &&
            this.store.economicOverview[item.key].length > 0
          ) {
            json[item.key] =
              this.store.economicOverview[item.key].filter(
                (val) => val.Country == this.lastCountry
              )[0] || {};
          }
        }
        return json;
      } else {
        return {
          GDP: [],
          "GDP Annual Growth Rate": [],
          "Inflation Rate": [],
          "Interest Rate": [],
          "Credit Rating": [],
          "Business Confidence": [],
        };
      }
    },
    showBelowData() {
      if (
        Object.keys(this.store.economicOverview).length > 0 &&
        this.lastCountry
      ) {
        let json = {};
        for (let item of this.otherIndicators) {
          if (
            this.store.economicOverview[item.key] &&
            this.store.economicOverview[item.key].length > 0
          ) {
            json[item.key] =
              this.store.economicOverview[item.key].filter(
                (val) => val.Country == this.lastCountry
              )[0] || {};
          }
        }
        return json;
      } else {
        return {
          Currency: [],
          "Stock Market": [],
          "Government Bond 10Y": [],
        };
      }
    },
  },
  methods: {
    getImage(key){
      if(key == 'Currency'){
        return 'assets/images/country_flag/' + this.lastCountry.toLowerCase() + '.webp' 
      }else if(key == 'Stock Market'){
        return 'assets/images/incTrade.svg' 
      }else{
        return 'assets/images/incBank.svg' 
      }
    },
    callBarChart() {
      this.economyOverviewIndicators.forEach((item, index) => {
        if (this.store.economicOverview[item.key]) {

          if(this.lastCountry && this.store.economicOverview[item.key] && this.store.economicOverview[item.key].length > 0){
            let index = this.store.economicOverview[item.key].map(val => val.Country).indexOf(this.lastCountry)
            this.start = this.store.economicOverview[item.key].slice(parseInt(index)-10,parseInt(index))
            this.end = this.store.economicOverview[item.key].slice(parseInt(index),parseInt(index)+10)
            if(this.start.length == 0){
              this.start = [this.store.economicOverview[item.key][0]]
            }
            if(this.end.length == 0 ){
              this.end = [this.store.economicOverview[item.key][this.store.economicOverview[item.key].length - 1]]
            }
          }
          this.drawBarChart(index, item.key,this.start,this.end);
        }
      });
    },
    disposeRoot(id) {
      let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
      d?.dispose();
    },
    drawBarChart(index, key,from,to) {
      let id = "barChartdiv" + index;
      this.disposeRoot(id);
      var root = am5.Root.new(id);
      root.interfaceColors.set("grid", am5.color(0xffffff));

      root.setThemes([am5themes_Animated.new(root)]);

      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX"
      }));
      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          visible: false,
          maxDeviation: 0.3,
          categoryField: "Country",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          visible: false,
          maxDeviation: 0.3,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series 1",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "LatestValue",
          sequencedInterpolation: true,
          categoryXField: "Country",
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
      });
      series.columns.template.setAll({
        tooltipText: "{Country}"
      });
      series.set("stroke", "#E8E8E8");
      series.columns.template.adapters.add("fill", (fill, target) => {
        if (target._dataItem.dataContext.Country == this.lastCountry) {
          return "#16C784";
        } else {
          return "#E8E8E8";
        }
      });

      let tooltip = am5.Tooltip.new(root, {
        autoTextColor: false,
        getFillFromSprite: false,
        labelText: "{Country}",
        fill : am5.color(0xffffff)
      });

      tooltip.get("background").setAll({
        fill: am5.color('#16C784'),
        fillOpacity: 0.8,
        stroke: am5.color(0xffffff)
      });

      series.set("tooltip", tooltip);



      var data = this.store.economicOverview[key];

      xAxis.data.setAll(data);
      series.data.setAll(data);
      const _that = this;
      if(from.length > 0 && to.length > 0){
        series.events.once("datavalidated", function(ev, target) {
          if(to.length > 5){
            xAxis.zoomToCategories(from[0].Country,to[5].Country)
          }else{
            xAxis.zoomToCategories(from[0].Country,to[0].Country)
          }
        })
      }
      chart.zoomOutButton.set("forceHidden", true);
      series.appear(1000);
      chart.appear(1000, 100);
    },
    drawWorldChart(country) {
      this.disposeRoot("homechartdiv");
      var root = am5.Root.new("homechartdiv");
      root.setThemes([am5themes_Animated.new(root)]);
      let json = {};
      if(this.isMobile){
        json['panX'] = 'none'
        json['panY'] = 'none'
        projection: am5map.geoMercator()
      }else{
        json['panX'] = "translateX"
        json['panY'] = "translateY"
        projection: am5map.geoMercator()
      }
      var chart = root.container.children.push(
        am5map.MapChart.new(root, json)
      );

      var polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
        })
      );
      polygonSeries.useGeodata = true;
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        toggleKey: "active",
        interactive: true,
        templateField: "polygonSettings",
        fill: "#E8E8E8",
      });
      polygonSeries.mapPolygons.template.states.create("hover", {
        fill: "#16C784",
      });

      polygonSeries.mapPolygons.template.states.create("active", {
        fill: "#16C784",
      });
      var previousPolygon;
      polygonSeries.mapPolygons.template.on("active", (active, target) => {
        this.lastCountry = target.dataItem.dataContext.name;
        this.callBarChart();
        if (previousPolygon && previousPolygon != target) {
          previousPolygon.set("active", false);
        }
        if (target.get("active")) {
          polygonSeries.data.setAll([
            {
              id: target.dataItem.dataContext.id,
              polygonSettings: {
                active: true,
              },
            },
          ]);
        } else {
          chart.goHome();
        }
        previousPolygon = target;
      });
      if (country) {
        let countryObject = am5geodata_worldLow.features.find(
          (i) => i.properties.name === country
        );
        polygonSeries.data.setAll([
          {
            id: countryObject.id,
            polygonSettings: {
              active: true,
            },
          },
        ]);
      }
      chart.appear(1000, 100);
    },
    getEconomicOverview() {
      let formData = {
        indicator: [
          "GDP",
          "Interest Rate",
          "Credit Rating",
          "GDP Annual Growth Rate",
          "Stock Market",
          "Business Confidence",
          "Government Bond 10Y",
          "Inflation Rate",
          "Currency",
        ],
      };
      this.store
        .callEconomicOverview(formData, false)
        .then((response) => {
          if(response["GDP"] && response["GDP"].length > 0){
            this.lastCountry =
              response["GDP"][response["GDP"].length - 1].Country;
          }
            //this.zoomRange.concat()
          am5.ready(() => {
            this.drawWorldChart(this.lastCountry);
            this.callBarChart();
          });
        })
        .catch((err) => console.log(err.message));
    },
  },
  mounted(){
    const _that = this;
    $(document).ready(function ($) {
      var alterClass = function () {
        var ww = document.body.clientWidth;
        if (ww < 991) {
          _that.isMobile = true
        } else if (ww >= 992) {
            _that.isMobile = false
        }
      };
      $(window).resize(function () {
        alterClass();
      });
      alterClass();
    });
  },
  created() {
    this.getEconomicOverview();
  },
};
</script>
<style>
#homechartdiv {
  width: 100%;
  height: 400px;
}
</style>
