<template>
    <section class="mainBanner blackBG pb-0">
        <div class="container pad-80">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-5 col-xxl-5">
                    <h1 class="breakTitle">
                        Grow <span>together</span> with<br />
                         passionate <span>people</span>
                    </h1>
                    <h3>
                        <!-- Here thoughts are shared to make<br />
            smart trading decisions -->
                       Sharing thoughts to make smarter <br />trading decisions
                    </h3>
                    <div class="search-container">
                        <form class="position-relative">
                            <input type="text" placeholder="Search EURUSD, USDJPY, USDCAD, etc..." name="search"
                                class="form-control" v-model="search" />
                            <a href="#" class="grd_btn">Launch Chart</a>
                            <ul class="dropdown_menu_animated searchResult show justify-content-between d-flex flex-wrap p-3 home-banner-search"
                                v-if="search">
                                <li v-for="item in searchisins" class="mb-2"><a class="py-2 border-0 text-center"
                                        href="javascript:void(0)"
                                        @click.prevent="changePage(item.other.isinid)">{{item.other.isinid}}</a></li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-lg-7 col-xxl-7">
                    <div class="marketBanner ms-lg-auto">
                        <div class="marketHeader d-flex align-items-center justify-content-between">
                            <h4 class="green mb-0">Markets</h4>
                            <ul class="marketList d-flex align-items-center mb-0">
                                <li v-for="tab of tabs" :key="tab">
                                    <a href="javascript:void(0)" :title="tab.toUpperCase()"
                                        @click="() => (currentTab = tab)">
                                        <img :src="'assets/images/symbol' + tab + '.svg'" alt="Icon"
                                            class="rounded-circle" :class="{ active: tab == currentTab }" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped fixed-layout">
                                <thead>
                                    <tr>
                                        <th class="ps-4 name">Symbol</th>
                                        <th>Bid</th>
                                        <th>Ask</th>
                                        <th>Chg</th>
                                        <th>Chg%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(symbol, index) in symbols" @click="() => changePage(symbol)"
                                        :key="index">
                                        <td class="name">
                                            <span class="d-flex align-items-center">
                                                <span
                                                    v-if="parseFloat(isins[symbol] ? isins[symbol].CB : 0) == 0">-</span>
                                                <span class="flex-none" :class="[
                                                  {
                                                    red:
                                                      parseFloat(
                                                        isins[symbol] ? isins[symbol].CB : 0
                                                      ) < 0,
                                                    green:
                                                      parseFloat(
                                                        isins[symbol] ? isins[symbol].CB : 0
                                                      ) > 0,
                                                  },
                                                ]" v-else>
                                                    <i class="fa" aria-hidden="true" :class="[
                                                      {
                                                        'fa-caret-down':
                                                          parseFloat(
                                                            isins[symbol] ? isins[symbol].CB : 0
                                                          ) < 0,
                                                        'fa-caret-up':
                                                          parseFloat(
                                                            isins[symbol] ? isins[symbol].CB : 0
                                                          ) > 0,
                                                      },
                                                    ]"></i>
                                                </span>
                                                <div class="name mx-2" v-if="currentTab == 'stocks'">
                                                    <span class="singleName">{{symbol.slice(0,1)}}</span>
                                                </div>
                                                <div class="dualIcon" v-else>
                                                    <a class="d-inline-flex mx-2" href="#" v-if="
                                                      currentTab != 'indices' &&
                                                      currentTab != 'commodities'
                                                    "><img :src="
                              'assets/images/crypto/' +
                              spliting(symbol, 0) +
                              '.webp'
                            " alt="" /></a>
                                                    <a class="d-inline-flex mx-2" :class="{
                                                      single: currentTab == 'commodities' || currentTab == 'indices'
                                                    }" href="#"><img :src="
                              'assets/images/crypto/' +
                              spliting(symbol, 1) +
                              '.webp'
                            " alt="" /></a>
                                                </div>
                                                <span class="pointer" @click="() => changePage(symbol)">{{ symbol
                                                }}</span>
                                            </span>
                                        </td>
                                        <td :class="{
                                          red:
                                            isins[symbol] &&
                                            isins[symbol].buy_increase_staus == 0,
                                          green:
                                            isins[symbol] &&
                                            isins[symbol].buy_increase_staus == 1,
                                        }">
                                            {{
                                            COMMON.formatPrice(
                                            isins[symbol] ? isins[symbol].B : 0,
                                            true
                                            )
                                            }}
                                        </td>
                                        <td :class="{
                                          red:
                                            isins[symbol] &&
                                            isins[symbol].sell_increase_staus == 0,
                                          green:
                                            isins[symbol] &&
                                            isins[symbol].sell_increase_staus == 1,
                                        }">
                                            {{
                                            COMMON.formatPrice(
                                            isins[symbol] ? isins[symbol].A : 0,
                                            true
                                            )
                                            }}
                                        </td>
                                        <td :class="{
                                          red: parseFloat(isins[symbol] && isins[symbol].CS) < 0,
                                          green:
                                            parseFloat(isins[symbol] && isins[symbol].CS) > 0,
                                        }">
                                            {{
                                            parseFloat(
                                            parseFloat(isins[symbol] ? isins[symbol].B : 0) -
                                            (isins[symbol] ? isins[symbol].other.closepx : 0)
                                            ).toFixed(4)
                                            }}
                                        </td>
                                        <td :class="{
                                          red: parseFloat(isins[symbol] && isins[symbol].CS) < 0,
                                          green:
                                            parseFloat(isins[symbol] && isins[symbol].CS) > 0,
                                        }">
                                            {{
                                            COMMON.formatPrice(
                                            isins[symbol] ? isins[symbol].CS : 0
                                            )
                                            }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <banner-slider></banner-slider>
    </section>
</template>

<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import bannerslider from "../../../components/shared/banner_slider";
export default {
    data() {
        return {//stocks
            currentTab: "forex",
            tabs: ["forex", "crypto", "commodities", "indices"],
            search: '',
        };
    },
    computed: {
        searchisins() {
            try {
                if (this.search != '') {
                    return Object.values(this.isins).filter(val => val.other.isinid.toLowerCase().includes(this.search.toLowerCase()))
                } else {
                    return []
                }
            } catch (ex) {
                return [];
            }
        },
        isins() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.asKeysValueArrayAll();
            } catch (ex) {
                return {};
            }
        },
        symbols() {
            return this.static_vars.isinids.home[this.currentTab]
                .map((i) => i.value)
                .slice(0, 4);
        },
    },
    components: {
        bannerSlider: bannerslider,
    },
    methods: {
        spliting(val, index) {
            if (val) {
                if (val.includes("-")) {
                    return val.split("-")[index].toLowerCase();
                } else if (val.length >= 6) {
                    if (index) {
                        return val.slice(3, 7).toLowerCase();
                    } else {
                        return val.slice(0, 3).toLowerCase();
                    }
                }
            } else {
                return "";
            }
        },
        changePage(symbol) {
            if (this.isins[symbol]) {
                this.$router.push({
                    path: "/instrument-description",
                    query: {
                        market: this.isins[symbol].other.isinid,
                        mn: this.isins[symbol].other.mn,
                        type : this.isins[symbol].other.type
                    },
                });
            }
        },
    },
};
</script>
