<template>
    <div class="d-flex align-items-center justify-content-between mb-2 mb-md-4">
        <h3 class="mb-0">Trade Ideas</h3>
        <router-link to="/trade-ideas" class="linkBtn px-3 d-block">See All</router-link><!-- d-md-none -->
    </div>
    <div class="arrowBlock" v-if="showIdea.length > 0">
        <div class="leftSide idea position-relative">
            <div class="card border-0 mb-4" v-for="item,key in showIdea">
                <div class="card-body homeFormatIdea">
                    <div class="cardUp">
                        <h4 class="card-title">
                            <a href="javascript:void(0)" @click="() => goToDetailPage(item)">{{item.title}}</a>
                            <span class="modifyDate">{{formatDate(item.created)}}</span>
                        </h4>
                        <img :src="imageLink+'/get/doc?type=TRADE&doc='+item.document+'&id='+item.user_id"
                            class="img-responsive" alt="">
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"
                                @click="() => goToProfilePage(item.user_id)">
                                <p class="IdeaUsername mb-0">{{item.firstname.charAt(0) || ''}}{{item.lastname.charAt(0)
                                ||
                                ''}}</p> <span class="mx-2">{{item.firstname || ''}}
                                    {{item.lastname || ''}}</span>
                            </div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="javascript:void(0)">{{item.symbol || 'N/A'}}</a></span>
                                <span class="monthly"><a href="javascript:void(0)">{{item.time_interval ||
                                'N/A'}}</a></span>
                                <span class="short"><a href="javascript:void(0)"
                                        :class="SymbolClass[item.position.toLowerCase()]"><i
                                            data-feather="arrow-down-right"></i> {{item.position || 'N/A'}}</a></span>
                            </div>
                        </div>
                        <p>{{item.description}}</p>
                    </div>
                    <div class="cardDown">
                        <div class="smallbtn d-flex align-items-center">
                            <span class="cardEelement">{{item.classification || 'N/A'}}</span>
                            <span class="cardEelement">{{item.type || 'N/A'}}</span>
                        </div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div @click="() => likeIdea(item,'like')">
                                <span><i :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'"
                                        aria-hidden="true"></i>{{item.likes_count || 0}}</span>
                            </div>
                            <div class="righticons">
                                <span @click="() => likeIdea(item,'comment')"><i class="fa fa-comments-o"
                                        aria-hidden="true"></i>{{item.comment_count || 0}}</span>
                                <span><i class="fa fa-share-alt" aria-hidden="true"></i>{{item.share_count || 0}}</span>
                                <span><i class="fa fa-bookmark-o" aria-hidden="true"></i>{{item.bookmarks_count ||
                                0}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="leftbottonScroll-btn text-center position-absolute">
            <!--:style="`top: ${height}px`" v-if="height"-->
            <router-link to="/trade-ideas" class="grd_btn btn_arrow">
                <vue-feather type="chevron-right"></vue-feather>
            </router-link><!-- @click="showMore = true"  v-if="!showMore" -->
            <!-- <a href="javascript:void(0)" class="grd_btn btn_arrow" @click="showMore = false"  v-if="showMore"><vue-feather type="chevron-"></vue-feather> </a> -->
        </div>
        <!-- <div class="mobTradeArrow d-block d-lg-none">
            <a href="javascript:void(0)" class="left_arrow">
                <vue-feather type="chevron-left"></vue-feather>
            </a>
            <a href="javascript:void(0)" class="right_arrow">
                <vue-feather type="chevron-right"></vue-feather>
            </a>
        </div> -->
    </div>

    <no-record v-else></no-record>
    <login v-if="showPopup"></login>
</template>
<script>
import { useMainStore } from "@/store";
import moment from 'moment'
import { Form, Field, ErrorMessage } from "vee-validate";
import login from "../../../components/shared/userlogin"
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            imageLink: process.env.VUE_APP_API_BASE_URL,
            showMore: false,
            showPopup: '',
            SymbolClass: {
                'neutral': 'yellowBG',
                'buy': 'greenBG',
                'sell': 'redBG'
            },
            height: 0,

        }
    },
    watch: {
        "store.calendarData"(val) {
            this.height = parseInt(this.$parent.$refs.rightBlock.clientHeight) + 420;
        },
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        login
    },
    computed: {
        showIdea() {
            if (this.store.tradeIdeasList.totalRecords && this.store.tradeIdeasList.totalRecords > 0) {
                if (!this.showMore) {
                    return this.store.tradeIdeasList.records.slice(0, 9)
                } else {
                    return this.store.tradeIdeasList.records.slice(0, 11)
                }
            } else {
                return []
            }
        }
    },
    methods: {
        goToProfilePage(id) {
            if (this.store.user.profile) {
                if (parseInt(this.store.user.profile.user_id) != parseInt(id)) {
                    this.$router.push({
                        name: 'profile',
                        query: { 'id': id }
                    });
                } else {
                    this.$router.push({
                        name: 'profile',
                    });
                }
            } else {
                this.$router.push({
                    name: 'profile',
                    query: { 'id': id }
                });
            }
        },
        goToDetailPage(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'details',
                params: { 'id': item.trade_id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': 1 }
            });
            /*/ +/g, '-'*/
        },
        likeIdea(item, type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if (type == 'like') {
                    this.callUpdateTrade(item)
                } else {
                    this.goToDetailPage(item)
                }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if (parseInt(item.is_like) == 1) {
                form['is_like'] = 0
            }
            if (this.store.tradeIdeasList.totalRecords) {
                this.store.tradeIdeasList.records.forEach(val => {
                    if (parseInt(val.trade_id) == parseInt(item.trade_id)) {
                        if (parseInt(val.is_like) == 1) {
                            val.is_like = 0
                            val.likes_count = parseInt(val.likes_count) - 1
                        } else {
                            val.is_like = 1
                            val.likes_count = parseInt(val.likes_count) + 1
                        }
                    }
                })
            }
            this.store.callUpdateIdeas(form, true)
        },
        formatDate(val) {
            return moment(val).format('DD MMM')
        },
        getIdeasList() {
            let formData = {
                page: 1,
                perPage: 11,
                "sort": { "field": "trade_ideas.created", "type": "DESC" }
            };
            if (this.store.user.profile) {
                formData['logged_user_id'] = this.store.user.profile.user_id
            }
            this.store
                .callTradeIdeasList(formData, false)
                .then((response) => {
                })
                .catch();
        }
    },
    created() {
        this.getIdeasList()
    }
}
</script>
<style scoped>
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>
