<style scoped>
.pivotChart {
  position: relative;
}
.pivotChart .r5,
.pivotChart .r4,
.pivotChart .r3,
.pivotChart .r2,
.pivotChart .r1,
.pivotChart .s1,
.pivotChart .s2,
.pivotChart .s3,
.pivotChart .s4,
.pivotChart .s5 {
  position: relative;
  height: 50px;
  width: 100%;
}
.pivotChart .r5 p,
.pivotChart .r4 p,
.pivotChart .r3 p,
.pivotChart .r2 p,
.pivotChart .r1 p {
  position: absolute;
  right: -20px;
  top: -10px;
}

.pivotChart .s1 p,
.pivotChart .s2 p,
.pivotChart .s3 p,
.pivotChart .s4 p,
.pivotChart .s5 p {
  position: absolute;
  right: -20px;
  bottom: -30px;
}

.pivotChart .pivot {
  height: 500px;
  width: 100%;
  top: 0px;
  position: absolute;
}
.pivotChart .pivot span {
  position: absolute;
  right: 0px;
  bottom: 250px;
  width: 100%;
  border-bottom: 1px dashed green;
  z-index: 99;
}
.pivotChart .pivot p {
  position: absolute;
  right: -40px;
  bottom: 240px;
  margin: 0;
  color: #3e8e73;
}

.pivotChart .r5 {
  background-color: rgb(244, 63, 63, 0.2);
}
.pivotChart .r4 {
  background-color: rgb(252, 109, 109, 0.2);
}
.pivotChart .r3 {
  background-color: rgb(254, 163, 163, 0.2);
}
.pivotChart .r2 {
  background-color: rgb(255, 201, 201, 0.2);
}
.pivotChart .r1 {
  background-color: rgb(255, 225, 225, 0.2);
}
.pivotChart .s1 {
  background-color: rgb(210, 249, 227, 0.2);
}
.pivotChart .s2 {
  background-color: rgb(169, 241, 205, 0.2);
}
.pivotChart .s3 {
  background-color: rgb(113, 228, 177, 0.2);
}
.pivotChart .s4 {
  background-color: rgb(56, 207, 145, 0.2);
}
.pivotChart .s5 {
  background-color: rgb(22, 199, 132, 0.2);
}
.pivotChart .r5 p {
  color: #3e8e73;
  font-size: 12px;
}
.pivotChart .r4 p {
  color: #3e8e73;
  font-size: 12px;
}
.pivotChart .r3 p {
  color: #4e997f;
  font-size: 12px;
}
.pivotChart .r2 p {
  color: #f3bb2d;
  font-size: 12px;
}
.pivotChart .r1 p {
  color: #f3bb2d;
  font-size: 12px;
}
.pivotChart .s1 p {
  color: #f3bb2d;
  font-size: 12px;
}
.pivotChart .s2 p {
  color: #e88018;
  font-size: 12px;
}
.pivotChart .s3 p {
  color: #fc6d6d;
  font-size: 12px;
}
.pivotChart .s4 p {
  color: #f43f3f;
  font-size: 12px;
}
.pivotChart .s5 p {
  color: #f43f3f;
  font-size: 12px;
}
.pivotpoint {
  border-radius: 100%;
  position: absolute;
  z-index: 5;
  transform: translate(50%, 250px);
  left: 0;
  right: 0;
  top: 0;
}

.r1bgColor {
  background-color: #f3bb2d;
  border: 1px solid #f3bb2d;
}
.r2bgColor {
  background-color: #f3bb2d;
  border: 1px solid #f3bb2d;
}
.r3bgColor {
  background-color: #4e997f;
  border: 1px solid #4e997f;
}
.r4bgColor {
  background-color: #3e8e73;
  border: 1px solid #3e8e73;
}
.r5bgColor {
  background-color: #3e8e73;
  border: 1px solid #3e8e73;
}
.s1bgColor {
  background-color: #f3bb2d;
  border: 1px solid #f3bb2d;
}
.s2bgColor {
  background-color: #e88018;
  border: 1px solid #e88018;
}
.s3bgColor {
  background-color: #fc6d6d;
  border: 1px solid #fc6d6d;
}
.s4bgColor {
  background-color: #f43f3f;
  border: 1px solid #f43f3f;
}
.s5bgColor {
  background-color: #f43f3f;
  border: 1px solid #f43f3f;
}
</style>
<template>
    <div class="row">
        <div class="col-12 col-xl-4 mb-4 mb-xl-0">
            <h3 class="mb-4 capitalize">{{ currentTab.toUpperCase() }}</h3>
        </div>
        <div class="col-12 col-xl-8">
            <div class="d-flex justify-content-between mb-4">
                <h3 class="mb-0">Pivot Points</h3>
                <div class="rightSelect">
                  <ul class="marketList d-flex align-items-center mb-0">
                    <li v-for="tab of tabs" :key="tab">
                      <a @click="() => (currentTab = tab)">
                        <img
                          :src="'assets/images/symbol' + tab + '.svg'"
                          alt="Icon"
                          class="rounded-circle"
                          :class="{ active: tab == currentTab }"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </div>
  <div class="row align-items-start">
    <div class="col-12 col-xl-4 mb-4 mb-xl-0">
      <div class="chartPattern">
        <ul class="tableStructure">
          <li
            v-for="symbol of symbols"
            class="d-flex align-items-center justify-content-between"
          >
            <span class="d-flex align-items-center">
              <!-- <img src="assets/images/chart3.png" class="me-2" alt="Icon"> -->
              {{ symbol }}
            </span>
            <span
              :class="{
                red: isins[symbol] && isins[symbol].buy_increase_staus == 0,
                green: isins[symbol] && isins[symbol].buy_increase_staus == 1,
              }"
              >{{ isins[symbol] ? isins[symbol].B : "-" }}</span
            >
            <span
              :class="{
                green:
                  COMMON.formatPrice(isins[symbol] ? isins[symbol].CB : 0) > 0,
              }"
              >{{
                isins[symbol] ? `${COMMON.formatPrice(isins[symbol].CB)}%` : "-"
              }}</span
            >
            <!-- <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span> -->
          </li>
          
        </ul>
      </div>
    </div>
    <div class="col-12 col-xl-8">
      <div class="customChartbar">
        {{ calculatePivots }}
        <div id="pivotChart" class="pivotChart">
          <div
            v-for="symbol of symbols"
            :key="symbol"
            :id="symbol"
            :title="`${symbol}(${
              isins[symbol] ? COMMON.formatPrice(isins[symbol].CB) : 0
            }%)`"
            class="pivotpoint pointer"
          ><span class="pivotSymbol" v-if="isins[symbol] && isins[symbol].CB">{{symbol}}</span></div>
          <div class="r5">
            <p>R5</p>
          </div>
          <div class="r4">
            <p>R4</p>
          </div>
          <div class="r3">
            <p>R3</p>
          </div>
          <div class="r2">
            <p>R2</p>
          </div>
          <div class="r1">
            <p>R1</p>
          </div>
          <div class="pivot">
            <span></span>
            <p>Pivot</p>
          </div>
          <div class="s1">
            <p>S1</p>
          </div>
          <div class="s2">
            <p>S2</p>
          </div>
          <div class="s3">
            <p>S3</p>
          </div>
          <div class="s4">
            <p>S4</p>
          </div>
          <div class="s5">
            <p>S5</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";

export default {
  data() {
    return {
      tabs: ["forex", "crypto", "commodities", "indices"],
      currentTab: "forex",
      classes: [
        "r1bgColor",
        "r2bgColor",
        "r3bgColor",
        "r4bgColor",
        "r5bgColor",
        "s1bgColor",
        "s2bgColor",
        "s3bgColor",
        "s4bgColor",
        "s5bgColor",
        "green",
        "red",
      ],
    };
  },
  computed: {
    isins() {
      try {
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.asKeysValueArrayAll();
      } catch (ex) {
        return {};
      }
    },
    symbols() {
      return this.static_vars.isinids.home[this.currentTab]
        .map((i) => i.value);
    },
    calculatePivots() {
      try {
        let rangeArr = [];
        for (let symbol of this.symbols) {
          if (this.isins[symbol]) {
            rangeArr.push(parseFloat(this.isins[symbol].CB));
          }
        }
        let rangePositive = this.getRange(rangeArr, "positive");
        let rangeNegative = this.getRange(rangeArr, "negative");
        let radius = 15;
        let width = document.getElementById("pivotChart")
          ? document.getElementById("pivotChart").getBoundingClientRect().width
          : 0;
        for (let symbol of this.symbols) {
          let symbolPrice = this.isins[symbol];
          let symbolElement = document.getElementById(symbol);
          if(symbolElement){
            symbolElement.classList.remove(...this.classes);
          }
          let xAxisPosition = 0;
          let elementHeightWidth = 0;
          if (symbolPrice && symbolElement) {
            let distance = 50;
            let change = parseFloat(symbolPrice.CB);
            let changeRelativePercentage = Math.abs(
              (change / (change > 0 ? rangePositive : rangeNegative)) * 100
            );
            let changeRelative = Math.abs(
              (changeRelativePercentage / 100) * width
            );
            let radiusRelative = Math.abs(
              (changeRelativePercentage / 100) * radius
            );
            let elementHeightWidth = radius + radiusRelative;
            if (change >= 0) {
              xAxisPosition = changeRelative + elementHeightWidth;
              symbolElement.classList.add("green");
              symbolElement.style.width = `${elementHeightWidth}px`;
              symbolElement.style.height = `${elementHeightWidth}px`;
            } else {
              xAxisPosition = width - changeRelative;
              symbolElement.classList.add("red");
              symbolElement.style.width = `${elementHeightWidth}px`;
              symbolElement.style.height = `${elementHeightWidth}px`;
            }
            let r5 = this.getPoints(symbolPrice, "r5"),
              r4 = this.getPoints(symbolPrice, "r4"),
              r3 = this.getPoints(symbolPrice, "r3"),
              r2 = this.getPoints(symbolPrice, "r2"),
              r1 = this.getPoints(symbolPrice, "r1"),
              pivot = this.getPoints(symbolPrice, "pivot"),
              s1 = this.getPoints(symbolPrice, "s1"),
              s2 = this.getPoints(symbolPrice, "s2"),
              s3 = this.getPoints(symbolPrice, "s3"),
              s4 = this.getPoints(symbolPrice, "s4"),
              s5 = this.getPoints(symbolPrice, "s5"),
              currentPrice = parseFloat(symbolPrice.A);
            if (currentPrice > pivot) {
              if (currentPrice < r1) {
                let percentage =
                  (parseFloat(currentPrice - pivot) / parseFloat(r1 - pivot)) *
                  100;
                let percent = (percentage / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 - (0 * distance + percent)
                }px)`;
                symbolElement.classList.add("r1bgColor");
              } else if (currentPrice < r2) {
                let percent =
                  (parseFloat(currentPrice - r1) / parseFloat(r2 - r1)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 - (1 * distance + percent)
                }px)`;
                symbolElement.classList.add("r2bgColor");
              } else if (currentPrice < r3) {
                let percent =
                  (parseFloat(currentPrice - r2) / parseFloat(r3 - r2)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 - (2 * distance + percent)
                }px)`;
                symbolElement.classList.add("r3bgColor");
              } else if (currentPrice < r4) {
                let percent =
                  (parseFloat(currentPrice - r3) / parseFloat(r4 - r3)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 - (3 * distance + percent)
                }px)`;
                symbolElement.classList.add("r4bgColor");
              } else if (currentPrice < r5) {
                let percent =
                  (parseFloat(currentPrice - r4) / parseFloat(r5 - r4)) * 100;
                percent = parseFloat((percent / 100) * 50);
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 - (4 * distance + percent)
                }px)`;
                symbolElement.classList.add("r5bgColor");
              } else {
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${elementHeightWidth}px)`;
                symbolElement.classList.add("r5bgColor");
              }
            } else {
              if (currentPrice > s1) {
                let percent =
                  (parseFloat(pivot - currentPrice) / parseFloat(pivot - s1)) *
                  100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 + (0 * distance + percent)
                }px)`;
                symbolElement.classList.add("s1bgColor");
              } else if (currentPrice > s2) {
                let percent =
                  (parseFloat(s1 - currentPrice) / parseFloat(s1 - s2)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 + (1 * distance + percent)
                }px)`;
                symbolElement.classList.add("s2bgColor");
              } else if (currentPrice > s3) {
                let percent =
                  (parseFloat(s2 - currentPrice) / parseFloat(s2 - s3)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 + (2 * distance + percent)
                }px)`;
                symbolElement.classList.add("s3bgColor");
              } else if (currentPrice > s4) {
                let percent =
                  (parseFloat(s3 - currentPrice) / parseFloat(s3 - s4)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 + (3 * distance + percent)
                }px)`;
                symbolElement.classList.add("s4bgColor");
              } else if (currentPrice > s5) {
                let percent =
                  (parseFloat(s5 - currentPrice) / parseFloat(s4 - s5)) * 100;
                percent = (percent / 100) * 50;
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  250 + (4 * distance + percent + elementHeightWidth)
                }px)`;
                symbolElement.classList.add("s5bgColor");
              } else {
                symbolElement.style.transform = `translate(${xAxisPosition}px, ${
                  500 - elementHeightWidth
                }px)`;
                symbolElement.classList.add("s5bgColor");
              }
            }
          }
        }
      } catch (error) {
        console.log("ERR", error);
      }
    },
  },
  methods: {
    getRange(arr, type) {
      if (type == "positive") {
        arr = arr.filter((i) => i > 0);
      } else {
        arr = arr.filter((i) => i < 0);
      }
      let min = arr.length == 1 ? 0 : Math.min(...arr);
      let max = arr.length == 1 ? arr[0] * 2 : Math.max(...arr);
      let range = Math.abs(max - min);
      return type == "positive" ? range + max : range - min;
    },
    getPoints(symbol, point) {
      //Pivot value
      if (point == "pivot") {
        return (symbol.H + symbol.L + symbol.other.closepx) / 3;
      }

      //Resistance values
      if (point == "r5") {
        return parseFloat(
          ((symbol.H - symbol.L) * 1.1) / 1 + symbol.other.closepx
        );
      }
      if (point == "r4") {
        return parseFloat(
          ((symbol.H - symbol.L) * 1.1) / 2 + symbol.other.closepx
        );
      }
      if (point == "r3") {
        return parseFloat(
          ((symbol.H - symbol.L) * 1.1) / 4 + symbol.other.closepx
        );
      }
      if (point == "r2") {
        return parseFloat(
          ((symbol.H - symbol.L) * 1.1) / 6 + symbol.other.closepx
        );
      }
      if (point == "r1") {
        return parseFloat(
          ((symbol.H - symbol.L) * 1.1) / 12 + symbol.other.closepx
        );
      }

      //Support values
      if (point == "s1") {
        return parseFloat(
          symbol.other.closepx - ((symbol.H - symbol.L) * 1.1) / 12
        );
      }
      if (point == "s2") {
        return parseFloat(
          symbol.other.closepx - ((symbol.H - symbol.L) * 1.1) / 6
        );
      }
      if (point == "s3") {
        return parseFloat(
          symbol.other.closepx - ((symbol.H - symbol.L) * 1.1) / 4
        );
      }
      if (point == "s4") {
        return parseFloat(
          symbol.other.closepx - ((symbol.H - symbol.L) * 1.1) / 2
        );
      }
      if (point == "s5") {
        return parseFloat(
          symbol.other.closepx - ((symbol.H - symbol.L) * 1.1) / 1
        );
      }
    },
  },
};
</script>
