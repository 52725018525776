<template>
    <!-- main Banner -->
    <main-banner></main-banner>
    <section class="blockElement overflow-hidden">
        <div class="container">
            <div class="row justify-content-between position-relative">
                <div :style="`height: ${height}px`" class="col-12 col-xl-4 max-30 pt-70 px-md-0 pb-0 customCol position-relative">
                    <home-left-bar></home-left-bar>
                </div>
                <div id="rightBlock" ref="rightBlock" class="col-12 col-xl-8 max-70 pl-70 py-70 halfBG customCol">
                    <latest-news :page="'dashboard'"></latest-news>
                    <div class="spacer"></div>
                    <trending-news></trending-news>
                    <div class="spacer"></div>
                    <market-trends class="hideMobile"></market-trends>
                    <div class="spacer"></div>
                    <!-- <chart-patterns class="hideMobile"></chart-patterns> -->
                    <!-- <div class="spacer"></div> -->
                    <market-insights></market-insights>
                    <div class="mb-md-5 mb-4"></div>
                    <sentiments-app></sentiments-app>
                    <div class="mb-md-5 mb-4"></div>
                    <economy-overview></economy-overview>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useMainStore } from "@/store";
import mainBanner from "./files/banner";
import homeleftbar from "./files/leftbar";
import latestnews from "./files/latest_news";
import trendingnews from "./files/trending_news";
import chartpatterns from "./files/chart_patterns";
import marketinsights from "./files/market_insights";
import markettrends from "./files/market_trends";
import sentimentsapp from "./files/sentiments";
import economyoverview from "./files/economy_overview";
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            height: 100,
        };
    },
    components: {
        mainBanner: mainBanner,
        homeLeftBar: homeleftbar,
        latestNews: latestnews,
        trendingNews: trendingnews,
        chartPatterns: chartpatterns,
        marketInsights: marketinsights,
        marketTrends: markettrends,
        sentimentsApp: sentimentsapp,
        economyOverview: economyoverview,
    },
    watch: {
        "store.calendarData"(val) {
            this.height = this.$refs.rightBlock.clientHeight;
        },
    },
};
</script>
