<template>
    <div class="row">
        <div class="col-12 mb-4">
            <div class="d-flex justify-content-between align-items-center mob-wrap">
                <h3 class="mb-0">Market Trends</h3>
                <ul class="smallbtn d-flex align-items-center mb-0">
                    <li>
                        <router-link to="/market-trend" class="cardEelement">See all</router-link>
                    </li>
                    <!-- <li><a href="#" class="cardEelement">Indices</a></li>
          <li><a href="#" class="cardEelement">Currencies</a></li>
          <li><a href="#" class="cardEelement">Crypto</a></li> -->
                </ul>
            </div>
        </div>
        <div class="col-12">
            <div class="table-responsive economic mkt border">
                <table class="table table-sm m-0 bg-white fixed-layout">
                    <thead>
                        <tr align="center" class="border-top-0">
                            <th>Symbol</th>
                            <th align="left">Price</th>
                            <th align="left">News</th>
                            <th align="left">Trade Ideas</th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(store.trendingIsins).length > 0">
                        <tr v-for="isin of trendingIsins">
                            <td v-if="isin">
                                <span class="d-flex align-items-center">
                                    <img src="assets/images/market-trend-flag1.png" alt="" />
                                    <span class="medium f-15 ctr ps-2 f-15" v-if="isin.other">{{ isin.other.mn
                                    }}<span class="d-block f-11 regular">{{
                                        isin.other.Description
                                        }}</span></span>
                                </span>
                            </td>
                            <td v-if="isin">
                                <span class="d-flex align-items-center">
                                    <span class="medium pe-2">{{ COMMON.formatPrice(isin.B, true) }} </span><span
                                        class="f-12 flex-shrink-0"
                                        :class="COMMON.formatPrice(isin.CB) > 0 ? 'green' : 'red'">{{
                                        COMMON.formatPrice(isin.CB) }}%</span>
                                </span>
                            </td>
                            <td width="25%" class="f-12 medium line-4" v-if="isin">
                                <span v-if="
                                  isin.other &&
                                  store.trendingIsins[isin.other.mn] &&
                                  store.trendingIsins[isin.other.mn].news.length
                                  && store.trendingIsins[
                      isin.other.mn
                    ].news[0].description
                                " v-html="
                    store.trendingIsins[
                      isin.other.mn
                    ].news[0].description.slice(0, 50)

                  " @click="goToDetailPage(store.trendingIsins[
                      isin.other.mn
                    ].news[0])"></span>
                    <span v-else-if="
                                  isin.other &&
                                  store.trendingIsins[isin.other.mn] &&
                                  store.trendingIsins[isin.other.mn].news.length
                                  && store.trendingIsins[
                      isin.other.mn
                    ].news[0].title
                                " v-html="
                    store.trendingIsins[
                      isin.other.mn
                    ].news[0].title.slice(0, 50)

                  " @click="goToDetailPage(store.trendingIsins[
                      isin.other.mn
                    ].news[0])"></span>
                                <span v-else>-</span>
                            </td>
                            <td v-if="isin">
                                <div v-if="
                                 isin.other &&
                                  store.trendingIsins[isin.other.mn] &&
                                  store.trendingIsins[isin.other.mn].trade.length
                                " class="d-flex align-items-center justify-content-between">
                                    <div class="perparing">
                                        <span class="mb-1 d-block f-12 medium">
                                            {{
                                            store.trendingIsins[isin.other.mn].trade[0].title
                                            }}
                                        </span>
                                        <!-- <div class="smallbtn intable d-flex align-items-center">
                                            <a href="javascript:void(0)" class="sharemkt me-1"><img
                                                    src="assets/images/bull.svg" /></a>
                                            <a href="javascript:void(0)" class="tagged">Long</a>
                                            <a href="javascript:void(0)" class="tagged">Premium</a>
                                        </div> -->
                                    </div>
                                    <!-- <div class="d-flex align-items-center">
                    <a href="javascript:void(0)" class="sharemkt large me-3"
                      ><img src="assets/images/bull-bear.svg"
                    /></a>
                    <a
                      href="javascript:void(0)"
                      :class="details ? 'added' : 'add'"
                      class="tradeBtn"
                      @click="details = !details"
                      ><vue-feather
                        :type="details ? 'minus' : 'plus'"
                        size="16px"
                      ></vue-feather
                    ></a>
                  </div> -->
                                </div>
                                <div v-else>
                                    <span>-</span>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
              <td>
                <span class="d-flex align-items-center">
                  <img src="assets/images/market-trend-flag1.png" alt="" />
                  <span class="medium f-15 ctr ps-2 f-15"
                    >EUR-USD<span class="d-block f-11 regular"
                      >EURO / U.S DollarD</span
                    ></span
                  >
                </span>
              </td>
              <td>
                <span class="d-flex align-items-center">
                  <span class="medium pe-2">1.18816 </span
                  ><span class="red f-12 flex-shrink-0">- 1.45%</span>
                </span>
              </td>
              <td width="25%" class="f-12 medium line-4">
                US 10Y yields point to some consolidation around 1.28%...
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="perparing">
                    <span class="mb-1 d-block f-12 medium"
                      >GbpUsd- Perparing to brak 1.4?</span
                    >
                    <div class="smallbtn intable d-flex align-items-center">
                      <a href="javascript:void(0)" class="sharemkt me-1"
                        ><img src="assets/images/bull.svg"
                      /></a>
                      <a href="javascript:void(0)" class="tagged">Long</a>
                      <a href="javascript:void(0)" class="tagged">Premium</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <a href="javascript:void(0)" class="sharemkt large me-3"
                      ><img src="assets/images/bull-bear.svg"
                    /></a>
                    <a
                      href="javascript:void(0)"
                      :class="details ? 'added' : 'add'"
                      class="tradeBtn"
                      @click="details = !details"
                      ><vue-feather
                        :type="details ? 'minus' : 'plus'"
                        size="16px"
                      ></vue-feather
                    ></a>
                  </div>
                </div>
              </td>
            </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import { useMainStore } from "@/store";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            details: false,
            details2: false,
            details3: false,
            details4: false,
            details5: false,
        };
    },
    methods:{
        goToDetailPage(item){
            let title = item.title
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    title = item.title
                }
            }
            this.$router.push({
                name: 'news_details',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
            });
        },
    },
    computed: {
        trendingIsinsAll() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.getTrendingIsinsHome();
            } catch (ex) {
                return {};
            }
        },
        trendingIsins() {
            let isins = Object.values(this.trendingIsinsAll);
            if (isins.length) {
                let currency = isins.find((i) => {
                    return (
                        !this.static_vars.crossPairs.includes(i.other.isinid) &&
                        i.other.type == "currencies"
                    );
                });
                let index = isins.find((i) => {
                    return (
                        !this.static_vars.crossPairs.includes(i.other.isinid) &&
                        i.other.type == "INDICIES"
                    );
                });
                let commodity = isins.find((i) => {
                    return (
                        !this.static_vars.crossPairs.includes(i.other.isinid) &&
                        i.other.type == "Commodities"
                    );
                });
                let crypto = isins.find((i) => {
                    return (
                        !this.static_vars.crossPairs.includes(i.other.isinid) &&
                        i.other.type == "crypto"
                    );
                });
                return [currency,index,commodity, crypto];
            } else {
                return [];
            }
        },
    },
};
</script>
